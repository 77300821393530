import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app'

const components = {
  ContentDoc: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/ContentDoc.vue" /* webpackChunkName: "components/content-doc" */).then(c => c.default || c)),
  ContentList: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/ContentList.vue" /* webpackChunkName: "components/content-list" */).then(c => c.default || c)),
  ContentNavigation: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/ContentNavigation.vue" /* webpackChunkName: "components/content-navigation" */).then(c => c.default || c)),
  ContentQuery: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/ContentQuery.vue" /* webpackChunkName: "components/content-query" */).then(c => c.default || c)),
  ContentRenderer: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/ContentRenderer.vue" /* webpackChunkName: "components/content-renderer" */).then(c => c.default || c)),
  ContentRendererMarkdown: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/ContentRendererMarkdown.vue" /* webpackChunkName: "components/content-renderer-markdown" */).then(c => c.default || c)),
  ContentSlot: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/ContentSlot.vue" /* webpackChunkName: "components/content-slot" */).then(c => c.default || c)),
  DocumentDrivenEmpty: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/DocumentDrivenEmpty.vue" /* webpackChunkName: "components/document-driven-empty" */).then(c => c.default || c)),
  DocumentDrivenNotFound: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/DocumentDrivenNotFound.vue" /* webpackChunkName: "components/document-driven-not-found" */).then(c => c.default || c)),
  Markdown: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Markdown.vue" /* webpackChunkName: "components/markdown" */).then(c => c.default || c)),
  ProseA: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseA.vue" /* webpackChunkName: "components/prose-a" */).then(c => c.default || c)),
  ProseBlockquote: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseBlockquote.vue" /* webpackChunkName: "components/prose-blockquote" */).then(c => c.default || c)),
  ProseCode: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseCode.vue" /* webpackChunkName: "components/prose-code" */).then(c => c.default || c)),
  ProseCodeInline: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseCodeInline.vue" /* webpackChunkName: "components/prose-code-inline" */).then(c => c.default || c)),
  ProseEm: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseEm.vue" /* webpackChunkName: "components/prose-em" */).then(c => c.default || c)),
  ProseH1: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseH1.vue" /* webpackChunkName: "components/prose-h1" */).then(c => c.default || c)),
  ProseH2: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseH2.vue" /* webpackChunkName: "components/prose-h2" */).then(c => c.default || c)),
  ProseH3: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseH3.vue" /* webpackChunkName: "components/prose-h3" */).then(c => c.default || c)),
  ProseH4: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseH4.vue" /* webpackChunkName: "components/prose-h4" */).then(c => c.default || c)),
  ProseH5: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseH5.vue" /* webpackChunkName: "components/prose-h5" */).then(c => c.default || c)),
  ProseH6: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseH6.vue" /* webpackChunkName: "components/prose-h6" */).then(c => c.default || c)),
  ProseHr: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseHr.vue" /* webpackChunkName: "components/prose-hr" */).then(c => c.default || c)),
  ProseImg: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseImg.vue" /* webpackChunkName: "components/prose-img" */).then(c => c.default || c)),
  ProseLi: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseLi.vue" /* webpackChunkName: "components/prose-li" */).then(c => c.default || c)),
  ProseOl: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseOl.vue" /* webpackChunkName: "components/prose-ol" */).then(c => c.default || c)),
  ProseP: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseP.vue" /* webpackChunkName: "components/prose-p" */).then(c => c.default || c)),
  ProseStrong: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseStrong.vue" /* webpackChunkName: "components/prose-strong" */).then(c => c.default || c)),
  ProseTable: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseTable.vue" /* webpackChunkName: "components/prose-table" */).then(c => c.default || c)),
  ProseTbody: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseTbody.vue" /* webpackChunkName: "components/prose-tbody" */).then(c => c.default || c)),
  ProseTd: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseTd.vue" /* webpackChunkName: "components/prose-td" */).then(c => c.default || c)),
  ProseTh: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseTh.vue" /* webpackChunkName: "components/prose-th" */).then(c => c.default || c)),
  ProseThead: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseThead.vue" /* webpackChunkName: "components/prose-thead" */).then(c => c.default || c)),
  ProseTr: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseTr.vue" /* webpackChunkName: "components/prose-tr" */).then(c => c.default || c)),
  ProseUl: defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/components/Prose/ProseUl.vue" /* webpackChunkName: "components/prose-ul" */).then(c => c.default || c))
}

export default defineNuxtPlugin(nuxtApp => {
  for (const name in components) {
    nuxtApp.vueApp.component(name, components[name])
    nuxtApp.vueApp.component('Lazy' + name, components[name])
  }
})
