import { default as _47opt_47build_47repo_47pages_47_91_91slug_93_93_46vueMeta } from "/opt/build/repo/pages/[[slug]].vue?macro=true";
export default [
  {
    name: _47opt_47build_47repo_47pages_47_91_91slug_93_93_46vueMeta?.name ?? "slug",
    path: _47opt_47build_47repo_47pages_47_91_91slug_93_93_46vueMeta?.path ?? "/:slug?",
    file: "/opt/build/repo/pages/[[slug]].vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47_91_91slug_93_93_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47_91_91slug_93_93_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47_91_91slug_93_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[[slug]].vue").then(m => m.default || m)
  }
]