import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64nuxt_content_dist_runtime_plugins_ws_mjs_JuoSZH52OQ from "/opt/build/repo/node_modules/@nuxt/content/dist/runtime/plugins/ws.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_sentry_ts_3AyO8nEfhE from "/opt/build/repo/plugins/sentry.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64nuxt_content_dist_runtime_plugins_ws_mjs_JuoSZH52OQ,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_sentry_ts_3AyO8nEfhE
]